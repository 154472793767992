import {graphql, useStaticQuery} from 'gatsby';
import {navigate} from 'gatsby-link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import headerStyles from '../Header/Header.module.scss';
import styles from './GoogleCloudPlatform.module.scss';
import Advantages from '../Advantages';
import ExpandingCard from '../ExpandingCard';
import Feed from '../Feed';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import LeaveMessageBanner from '../LeaveMessageBanner';
import SuccessStoriesBanner from '../SuccessStoriesBanner';
import {
  GCP_CONSULTING_CARDS, GCP_CONSULTING_HEADING, GCP_CONSULTING_SUBHEADING, GCP_ENGINEERING_CARDS,
  GCP_ENGINEERING_HEADING, GCP_ENGINEERING_SUBHEADING, GCP_HEADING, GCP_INSIGHTS_HEADING, GCP_MANAGED_CARDS,
  GCP_MANAGED_HEADING, GCP_MANAGED_SUBHEADING, GCP_READY_HEADING, GCP_READY_SUBHEADING, GCP_SUBHEADING,
  GCP_SUPPORT_HEADING, GCP_SUPPORT_FIRST_PARAGRAPH, GCP_SUPPORT_ICONS, GCP_SUPPORT_LAST_PARAGRAPH, GCP_WHY_CV_CONTENT,
  GCP_WHY_CV_HEADING, GCP_WHY_GCP_BANNER_BUTTON, GCP_WHY_GCP_BANNER_TEXT, GCP_WHY_GCP_HEADING, GCP_WHY_GCP_LIST_ITEMS,
  GCP_WHY_GCP_PARAGRAPHS
} from '../../constants/google-cloud-platform';

const GoogleCloudPlatform = () => {
  const {bg, icons, insightsPosts, minus, plus, successStoriesBackground} = useStaticQuery(graphql`
    query {
      bg: allFile(
        filter: {
          sourceInstanceName: { eq: "GCP" }
          name: { eq: "bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      successStoriesBackground: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "success-stories-bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      icons: allFile(
        filter: {
          sourceInstanceName: { eq: "GCP" }
          extension: { eq: "svg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      minus: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "minus" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      plus: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "plus" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      insightsPosts: allMarkdownRemark(
        filter: {
          fields: {
            slug: {
              in: ["/posts/google-cloud-migration-strategies-and-opportunities-beyond-usual-cloud-efficiencies", "/posts/building-a-modern-digital-enterprise-with-google-cloud-data-fusion", "/posts/10-reasons-why-it-outsourcing-in-ukraine-is-a-perfect-choice-for-global-businesses"]
            }
          }
          frontmatter: {
            template: {
              eq: "post"
            }
            draft: {
              ne: true
            }
          }
        }
      ) {
        nodes {
          fields {
            slug
            categorySlug
            tagSlugs
          }
          frontmatter {
            title
            date
            category
            description
            socialImage
            tags
          }
        }
      }
    }
  `);

  const iconsMap = icons.nodes.reduce((acc, icon) => ({...acc, [icon.name]: icon.publicURL}), {});
  const postsEdges = insightsPosts.nodes.map(node => ({node}));

  return <div className={styles['gcp-page']}>
    <InnerPagesUpperBanner
      bg={bg.nodes[0].publicURL}
      className={styles['gcp-banner']}
      description={[GCP_SUBHEADING]}
      descriptionClassName={styles['gcp-banner-description']}
      text={[GCP_HEADING]}
      textClassName={styles['gcp-banner-text']}
    />
    <div className={`${styles['section-container']} ${styles['non-white']} ${styles['support-section']}`}>
      <div className={styles.container}>
        <Typography className={styles.heading} color="secondary" variant="h2" align="center">
          {GCP_SUPPORT_HEADING}
        </Typography>
        <Typography className={`${styles.subheading} ${styles['subheading-first-paragraph']}`}>
          {GCP_SUPPORT_FIRST_PARAGRAPH}
        </Typography>
        <Typography className={`${styles.subheading} ${styles['subheading-last-paragraph']}`}>
          {GCP_SUPPORT_LAST_PARAGRAPH}
        </Typography>
        <div className={styles['support-grid']}>
          {GCP_SUPPORT_ICONS.map(({icon, title}) => <div className={styles['support-grid-item']} key={icon}>
            <img className={styles['support-grid-icon']} src={iconsMap[icon]} />
            <div className={styles['support-grid-label']}>{title}</div>
          </div>)}
        </div>
      </div>
    </div>
    <div className={styles['section-container']}>
      <div className={styles.container}>
        <Typography className={styles.heading} color="secondary" variant="h2" align="center">
          {GCP_CONSULTING_HEADING}
        </Typography>
        <Typography className={styles.subheading}>{GCP_CONSULTING_SUBHEADING}</Typography>
        <div className={styles['card-list']}>
          {GCP_CONSULTING_CARDS.map(({icon, text, title}) => {
            return <div className={styles['expanding-card']} key={icon}>
              <ExpandingCard
                collapser={minus.nodes[0].publicURL}
                expander={plus.nodes[0].publicURL}
                img={iconsMap[icon]}
                smallCard
              >
                {{
                  collapsedView: <Typography
                    className={styles['expanding-card-heading']}
                    color="secondary"
                    variant="h4"
                  >
                    {title}
                  </Typography>,
                  expandedView: <div className={`expanding-card-container ${styles['expanding-card-container']}`}>
                    {text.map((paragraph, index) => <p
                      className={styles['expanding-card-paragraph']}
                      key={`${icon}-${index}`}
                    >
                      {paragraph}
                    </p>)}
                  </div>
                }}
              </ExpandingCard>
            </div>;
          })}
        </div>
      </div>
    </div>
    <div className={`${styles['section-container']} ${styles['non-white']}`}>
      <div className={styles.container}>
        <Typography className={styles.heading} color="secondary" variant="h2" align="center">
          {GCP_ENGINEERING_HEADING}
        </Typography>
        <Typography className={styles.subheading}>{GCP_ENGINEERING_SUBHEADING}</Typography>
        <div className={styles['card-list']}>
          {GCP_ENGINEERING_CARDS.map(({icon, text, title}) => {
            return <div className={styles['expanding-card']} key={icon}>
              <ExpandingCard
                collapser={minus.nodes[0].publicURL}
                expander={plus.nodes[0].publicURL}
                img={iconsMap[icon]}
                smallCard
              >
                {{
                  collapsedView: <Typography
                    className={styles['expanding-card-heading']}
                    color="secondary"
                    variant="h4"
                  >
                    {title}
                  </Typography>,
                  expandedView: <div className={`expanding-card-container ${styles['expanding-card-container']}`}>
                    {text.map((paragraph, index) => <p
                      className={styles['expanding-card-paragraph']}
                      key={`${icon}-${index}`}
                    >
                      {paragraph}
                    </p>)}
                  </div>
                }}
              </ExpandingCard>
            </div>;
          })}
        </div>
      </div>
    </div>
    <div className={styles['section-container']}>
      <div className={styles.container}>
        <Typography className={styles.heading} color="secondary" variant="h2" align="center">
          {GCP_MANAGED_HEADING}
        </Typography>
        <Typography className={styles.subheading}>{GCP_MANAGED_SUBHEADING}</Typography>
        <div className={styles['card-list']}>
          {GCP_MANAGED_CARDS.map(({icon, text, title}) => {
            return <div className={styles['expanding-card']} key={icon}>
              <ExpandingCard
                collapser={minus.nodes[0].publicURL}
                expander={plus.nodes[0].publicURL}
                img={iconsMap[icon]}
                smallCard
              >
                {{
                  collapsedView: <Typography
                    className={styles['expanding-card-heading']}
                    color="secondary"
                    variant="h4"
                  >
                    {title}
                  </Typography>,
                  expandedView: <div className={`expanding-card-container ${styles['expanding-card-container']}`}>
                    {text.map((paragraph, index) => <p
                      className={styles['expanding-card-paragraph']}
                      key={`${icon}-${index}`}
                    >
                      {paragraph}
                    </p>)}
                  </div>
                }}
              </ExpandingCard>
            </div>;
          })}
        </div>
      </div>
    </div>
    <SuccessStoriesBanner
      backgroundImage={successStoriesBackground.nodes[0].publicURL}
      className={styles['ready-banner']}
      descriptionClassName={styles['ready-banner-description']}
      heading={GCP_READY_HEADING}
      subHeading={GCP_READY_SUBHEADING}
      textClassName={styles['ready-banner-text']}
    />
    <div className={`${styles['section-container']} ${styles['non-white']} ${styles['why-gcp-section']}`}>
      <div className={styles.container}>
        <Typography align="center" className={styles.heading} color="secondary" variant="h2">
          {GCP_WHY_GCP_HEADING}
        </Typography>
        <div className={styles['why-gcp-grid']}>
          <div className={styles['why-gcp-paragraphs']}>
            {GCP_WHY_GCP_PARAGRAPHS.map((paragraph, index) => <p key={`why-gcp-p-${index}`}>
              {paragraph}
            </p>)}
          </div>
          <ul className={styles['why-gcp-list']}>
            {GCP_WHY_GCP_LIST_ITEMS.map((item, index) => <li key={`why-gcp-li-${index}`}>
              <span className={styles['why-gcp-list-item']}>{item}</span>
            </li>)}
          </ul>
        </div>
        <div className={styles['why-gcp-banner']}>
          <div className={styles['why-gcp-banner-text']}>{GCP_WHY_GCP_BANNER_TEXT}</div>
          <Button
            classes={{root: `${headerStyles['header__contact-us']} ${headerStyles['no-padding-media-query']}`}}
            color="primary"
            onClick={() => navigate('/expertise/google-cloud-platform/google-cloud-data-fusion')}
            variant="contained"
          >
            {GCP_WHY_GCP_BANNER_BUTTON}
          </Button>
        </div>
      </div>
    </div>
    <div className={`${styles['section-container']} ${styles['why-cv']}`}>
      <div className={styles.container}>
        <Advantages content={GCP_WHY_CV_CONTENT} heading={GCP_WHY_CV_HEADING} />
      </div>
    </div>
    <div className={`${styles['section-container']} ${styles['non-white']}`}>
      <div className={styles.container}>
        <Typography align="center" className={styles.heading} color="secondary" variant="h2">
          {GCP_INSIGHTS_HEADING}
        </Typography>
        <Feed edges={postsEdges} />
      </div>
    </div>
    <LeaveMessageBanner />
  </div>;
};

export default GoogleCloudPlatform;
